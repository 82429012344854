
import { defineComponent, onMounted, computed, ref, getCurrentInstance} from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import ApiService from "@/core/services/ApiService";
import * as JWT from '@/core/services/JwtService';
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import VPagination from "@hennge/vue3-pagination";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {AlertMessage} from "@/bundle/AlertMessage";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import AddResponsable from "@/components/modals/forms/addResponsable/AddResponsable.vue";


export default defineComponent({
  name: "responsables-list",
  components: {
    Dropdown2,
    VPagination,
    AddResponsable
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;

    const responsables = ref<any>([]);
    let page = ref(1);
    const totalItems = ref(0);
    let totalPages = ref(0);
    const store   = useStore();
    let pageSize = ref(10);
    let loading = ref(false);
    let init= ref(true);
    let offset = ref(0);
    let lastPage = ref(false);
    let numberOfElements = ref(0);
    let searchInput = ref(null);
    let sort = ref('nom');
    let order = ref('asc');

    const getResponsables= () => {
      loading.value = true;

      ApiService.query('/api/responsableTechnique/permissionnaires/' + JWT.getAuthUser()?.id, {
        params: {
          size: pageSize.value,
          page: page.value - 1,
          sort: `${sort.value},${order.value}`,
          search: searchInput.value
        }
      }).then((res: any) => {
        responsables.value = res.data.ResponsableTechnique;
        totalPages.value = res.data.totalPages;
        totalItems.value = res.data.totalItems;
        offset.value = res.data.Pages.offset;
        numberOfElements.value = res.data.ResponsableTechnique.length;
      }).catch(error => { 
        loading.value = false;
      });
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs("Responsables Techniques", []);
      getResponsables();
      
    });

    const openModalAddResponsable = () => {
      emitter.emit('open-add-responsable', {persist: true});
    }

    const openModalEditResponsable = (item) => {
      emitter.emit('open-edit-responsable', {persist: true, item: {...item}});
    }

    const hasResponsables = computed( () => {
      return responsables.value.length > 0
    });

    const searchResponsables = () => {
      page.value = 1;
      getResponsables();
    }

    const removeResponsable = (item) => {
      Swal.fire({
        title: 'Suppression d\'un responsable technique',
        icon: 'info',
        text: 'Etes-vous sur de vouloir supprimer ce responsable retechnique?',
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: 'Annuler',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#009EF7',
        cancelButtonColor: '#F1416C',
        reverseButtons: true,
        preConfirm: (login) => {
          return ApiService.delete(`/api/responsableTechnique/${item.id}`)
            .then((response: any) => {
              if (response.statusText !== "OK") {
                throw new Error(response.statusText)
              }
              return response
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Vous ne pouvez pas supprimer ce responsable technique, il est lié à une déclaration.`
              );
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          AlertMessage.onSuccess("Le responsable technique a été supprimé", "Suppression").then(() => {
            let index = responsables.value.findIndex(x => x.id === item.id);
            if(index !== -1){
              responsables.value.splice(index, 1);
            }
          });
        }
      });
    }

    onMounted(() => {
      emitter.on('add-new-responsable', (data: any) => {
        responsables.value.push({...data});
      });

      emitter.on('update-responsable', (data: any) => {
        let index = responsables.value.findIndex(x => x.id === data.id);
        if(index !== -1){
          responsables.value[index] = {...data};
        }
      });

    })
    

    /*
    * Permet de recuperer une entité spécifique
    */
    const permissionnaire = computed(() => {
      return JWT.getAuthUser();
    });

    const sortItems = (sortS) => {
      sort.value = sortS;
      order.value = (order.value === 'asc') ? 'desc' : 'asc';

      getResponsables();
    }

    return {
      getResponsables,
      openModalAddResponsable,
      openModalEditResponsable,
      removeResponsable,
      sortItems,
      searchResponsables,
      responsables,
      hasResponsables,
      permissionnaire,
      totalItems,
      totalPages,
      pageSize,
      page,
      offset,
      loading,
      init,
      lastPage,
      numberOfElements,
      order,
      sort,
      searchInput,
    };
  },
});
